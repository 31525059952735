import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Container, Table } from 'react-bootstrap';
import axiosInstance from '../../axiosInstance'; 
import InfoPopover from '../Pdf/listvariable';

function OrganizationFiles() {
    const [files, setFiles] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [newFile, setNewFile] = useState(null);
    const [newTitle, setNewTitle] = useState('');
    const [titleError, setTitleError] = useState('');
    const [fileError, setFileError] = useState('');

    useEffect(() => {
        fetchFiles();
    }, []);

    const fetchFiles = () => {
        axiosInstance.get(`/orgafiles/`)
            .then(response => {
                const data = response.data;
                setFiles(data.results || data);
            })
            .catch(error => console.error('Error fetching files:', error));
    };

    const handleFileUpload = () => {
        if (!newTitle || !newFile) {
            if (!newTitle) {
                setTitleError('Title is required.');
            }
            if (!newFile) {
                setFileError('Please select a file.');
            }
            return;
        }
        const formData = new FormData();
        formData.append('file', newFile);
        formData.append('title', newTitle);

        axiosInstance.post(`/orgafiles/`, formData)
            .then(() => {
                fetchFiles();
                setShowModal(false);
                setNewFile(null);
                setNewTitle('');
            })
            .catch(error => console.error('Error uploading file:', error));
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (!file) {
            setFileError('Please select a file.');
        } else {
            setFileError('');
        }
        setNewFile(file);
    };

    const handleTitleChange = (event) => {
        const title = event.target.value;
        if (!title.trim()) {
            setTitleError('Title is required.');
        } else {
            setTitleError('');
        }
        setNewTitle(title);
    };

    const handleDeleteFile = (fileId) => {
        axiosInstance.delete(`/orgafiles/${fileId}/`)
            .then(() => {
                // Refresh the list after deleting
                fetchFiles();  
            })
            .catch(error => console.error('Error deleting file:', error));
    };

    return (
        <Container fluid>
            <h2>Template sous forme de fichier</h2>
            <Button variant="primary" onClick={() => setShowModal(true)}>
                Nouveau document
            </Button>
            <InfoPopover/>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Title</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {files.map(file => (
                        <tr key={file.id}>
                            <td>{file.title}</td>
                            <td>
                                <Button variant="danger" onClick={() => handleDeleteFile(file.id)} style={{ marginLeft: "10px" }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" viewBox="0 0 16 16">
                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                                </svg>
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Télécharger un nouveau document</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>Document Title</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter title"
                                value={newTitle}
                                onChange={handleTitleChange}
                                isInvalid={!!titleError}
                            />
                            <Form.Label>File</Form.Label>
                            <Form.Control
                                type="file"
                                onChange={handleFileChange}
                                isInvalid={!!fileError}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleFileUpload}>
                        Upload
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
}

export default OrganizationFiles;
