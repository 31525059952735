import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axiosInstance from '../../axiosInstance'; 
import { Container, Row, Table, Button, Modal, Form, Col } from 'react-bootstrap';
import { Autocomplete } from '@mui/material';
import TextField from '@mui/material/TextField';

function ListContact() {
    const { expertiseId } = useParams();
    const [contacts, setContacts] = useState([]);
    const initialContactState = { 
        genre: '',
        name: '', 
        firstname: '', 
        mail: '', 
        phone: '',
        role1: '', 
        role2: '', 
        reference: '', 
        letterbox: '', 
        number: '',
        street: '', 
        postcode: '', 
        city: '', 
        bank_account: '',
        model: false,
    };
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [currentContact, setCurrentContact] = useState({ ...initialContactState });
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [contactToDelete, setContactToDelete] = useState(null);
    const [query, setQuery] = useState('');
    const [options, setOptions] = useState([]);

    useEffect(() => {
        fetchContacts();
    }, [expertiseId]);

    const fetchContacts = async () => {
        try {
            const response = await axiosInstance.get(`/personne/?expertise_id=${expertiseId}`);
            setContacts(response.data.results || response.data);
        } catch (error) {
            console.error("Erreur lors de la récupération des contacts: ", error);
        }
    };
    const handleShowAddModal = () => {
        setCurrentContact({ ...initialContactState }); // Réinitialise le formulaire
        setShowAddModal(true); // Ouvre le modal
    };
    
    const handleCloseAddModal = () => setShowAddModal(false);
    const handleShowEditModal = (contact) => {
        setCurrentContact(contact);
        setShowEditModal(true);
    };
    const handleCloseEditModal = () => setShowEditModal(false);

    const handleShowDeleteModal = (contactId) => {
        setContactToDelete(contactId);
        setShowDeleteModal(true);
    };
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setCurrentContact({ ...currentContact, [name]: value });
    };

    const handleAddSubmit = async (e) => {
        e.preventDefault();
        try {
            await axiosInstance.post(`/personne/`, { ...currentContact, expertise: expertiseId });
            fetchContacts();
            setCurrentContact({ ...initialContactState }); 
            handleCloseAddModal();

        } catch (error) {
            console.error("Erreur lors de l'ajout du contact: ", error);
        }
    };

    const handleEditSubmit = async (e) => {
        e.preventDefault();
        try {
            await axiosInstance.put(`/personne/${currentContact.id}/`, currentContact);
            fetchContacts();
            handleCloseEditModal();
        } catch (error) {
            console.error("Erreur lors de la modification du contact: ", error);
        }
    };

    const handleDeleteContact = async () => {
        if (contactToDelete) {
            try {
                await axiosInstance.delete(`/personne/${contactToDelete}`);
                fetchContacts(); // Rechargez la liste des contacts après la suppression
                setShowDeleteModal(false); // Fermez le modal de confirmation
            } catch (error) {
                console.error("Erreur lors de la suppression du contact: ", error);
            }
        }
    };
    const fetchResults = async () => {
        if (query !== '') {
            try {
                const response = await axiosInstance.get(`/persons/?name=${query}`);
                setOptions(response.data.results || response.data); // Assurez-vous que cela correspond à la structure de votre réponse API
                console.log(options)
            } catch (error) {
                console.error("Erreur lors de la recherche: ", error);
            }
        }
    };
    useEffect(() => {
        fetchResults();
    }, [query]);

    return (
      <Container fluid>
        <Row className="justify-content-md-center">
          <h2 className="expertise-title">Liste des intervenants</h2>
          <Button variant="outline-primary" onClick={handleShowAddModal}>
            Ajouter un intervenant
          </Button>
          <br />
          <div class="table-container">
            <Table striped bordered hover>
                <thead>
                    <tr>
                    <th class="fixed-column-left">#</th>
                    <th>Nom</th>
                    <th>Rôle</th>
                    <th>E-mail</th>
                    <th>Téléphone</th>
                    <th class="fixed-column">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {contacts.map((contact, index) => (
                    <tr key={contact.id}>
                        <td class="fixed-column-left">{index + 1}</td>
                        <td>{contact.name}</td>
                        <td>{contact.role1}</td>
                        <td>{contact.mail}</td>
                        <td>{contact.phone}</td>
                        <td class="fixed-column">
                            <Button variant="outline-primary" onClick={() => handleShowEditModal(contact)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5L13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5H5h.5v.5v.5v.5h.293l6.5-6.5zm-9.761 5.175l-.106.106-1.528 3.821 3.821-1.528.106-.106L3.707 10 2.032 10.675zm-.032 3.282l-.394.986.986-.394 2.598-2.598-.978-.978-2.212 2.984z"/>
                                </svg>
                            </Button>
                            <Button variant="outline-danger" onClick={() => handleShowDeleteModal(contact.id)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" viewBox="0 0 16 16">
                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                                </svg>
                            </Button>
                        </td>
                    </tr>
                    ))}
                </tbody>
            </Table>
          </div>
        
        </Row>
        <Modal show={showAddModal} onHide={handleCloseAddModal} backdrop="static" size="lg">
            <Modal.Header closeButton>
            <Modal.Title>Ajouter un nouvel intervenant</Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleAddSubmit}>
                <Modal.Body>
                
                <Autocomplete
                    freeSolo
                    options={options} // `options` est un tableau d'objets
                    getOptionLabel={(option) => `${option.name} ${option.firstname || ''}, ${option.city || ''}`}
                    onInputChange={(event, newInputValue) => {
                        setQuery(newInputValue);
                    }}
                    renderInput={(params) => (
                        <TextField {...params} label="Rechercher un nom" variant="outlined" />
                    )}
                    onChange={(event, newValue) => {
                        if (newValue && typeof newValue === 'object') {
                            setCurrentContact({
                                ...initialContactState, // Utilise l'état initial pour garantir des valeurs par défaut
                                ...newValue, // Remplace par les valeurs sélectionnées, s'assure que toutes les clés existent déjà dans initialContactState
                            });
                        } else {
                            setCurrentContact({ ...initialContactState });
                        }
                    }}
                />

                <Row>
                    {/* Genre */}
                    <Col md={2}> {/* Vous pouvez ajuster la taille en fonction du design souhaité */}
                        <Form.Group className="mb-3" controlId="formBasicGenre">
                            <Form.Label>Genre</Form.Label>
                            <Form.Select name="genre" onChange={handleChange} value={currentContact ? currentContact.genre : ''} >
                                <option value="">Choisir...</option>
                                <option value="Monsieur">Monsieur</option>
                                <option value="Madame">Madame</option>
                                <option value="Autre">Autre</option>
                            </Form.Select>
                        </Form.Group>
                    </Col>

                    {/* Nom */}
                    <Col md={5}> {/* Ajustez la largeur selon vos besoins */}
                        <Form.Group className="mb-3" controlId="formBasicName">
                            <Form.Label>Nom</Form.Label>
                            <Form.Control 
                                type="text" 
                                name="name" 
                                placeholder="Entrez le nom" 
                                onChange={handleChange} 
                                value={currentContact ? currentContact.name : ''} 
                                required 
                            />
                        </Form.Group>
                    </Col>

                    {/* Prénom */}
                    <Col md={5}>
                        <Form.Group className="mb-3" controlId="formBasicFirstName">
                            <Form.Label>Prénom</Form.Label>
                            <Form.Control 
                                type="text" 
                                name="firstname" 
                                placeholder="Entrez le prénom" 
                                onChange={handleChange} 
                                value={currentContact ? currentContact.firstname : ''} 
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        {/* Email */}
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" name="mail" placeholder="Entrez l'email" onChange={handleChange} value={currentContact ? currentContact.mail : ''}  />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        {/* Téléphone */}
                        <Form.Group className="mb-3" controlId="formBasicPhone">
                            <Form.Label>Téléphone</Form.Label>
                            <Form.Control type="text" name="phone" placeholder="Entrez le numéro de téléphone" onChange={handleChange} value={currentContact ? currentContact.phone : ''}  />
                        </Form.Group>
                    </Col>
                </Row>
                

                <Row>
                    <Col md={6}>
                        {/* Rôle 1 */}
                        <Form.Group className="mb-3" controlId="formBasicRole1">
                            <Form.Label>Rôle 1</Form.Label>
                            <Form.Control type="text" name="role1" placeholder="Rôle principal" onChange={handleChange} value={currentContact ? currentContact.role1 : ''} />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        {/* Rôle 2 */}
                        <Form.Group className="mb-3" controlId="formBasicRole2">
                            <Form.Label>Rôle 2</Form.Label>
                            <Form.Control type="text" name="role2" placeholder="Rôle secondaire" onChange={handleChange} value={currentContact ? currentContact.role2 : ''} />
                        </Form.Group>                        
                    </Col>
                </Row>

                {/* Référence */}
                <Form.Group className="mb-3" controlId="formBasicReference">
                    <Form.Label>Référence</Form.Label>
                    <Form.Control type="text" name="reference" placeholder="Référence" onChange={handleChange} value={currentContact ? currentContact.reference : ''} />
                </Form.Group>

                {/* Adresse */}
                <Form.Group className="mb-3" controlId="formBasicAddress">
                    <Form.Label>Adresse</Form.Label>
                    <Row>
                        <Col md={2}>
                            <Form.Control placeholder="Boîte postale" name="letterbox" onChange={handleChange} value={currentContact ? currentContact.letterbox : ''} />
                        </Col>
                        <Col md={2}>
                            <Form.Control placeholder="Numéro" name="number" onChange={handleChange} value={currentContact ? currentContact.number : ''} />
                        </Col>
                        <Col md={8}>
                            <Form.Control placeholder="Rue" name="street" onChange={handleChange} value={currentContact ? currentContact.street : ''} />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <Form.Control placeholder="Code postal" name="postcode" onChange={handleChange} value={currentContact ? currentContact.postcode : ''} />
                        </Col>
                        <Col md={8}>
                        <Form.Control placeholder="Ville" name="city" onChange={handleChange} value={currentContact ? currentContact.city : ''} />
                        </Col>
                    </Row>
                </Form.Group>

                {/* Compte Bancaire */}
                <Form.Group className="mb-3" controlId="formBasicBankAccount">
                    <Form.Label>Compte Bancaire</Form.Label>
                    <Form.Control type="text" name="bank_account" placeholder="Compte bancaire" onChange={handleChange} value={currentContact ? currentContact.bank_account : ''} />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicModel">
                    <Form.Check 
                        type="checkbox" 
                        label="Enregistrer comme modèle" 
                        name="model" 
                        checked={!!currentContact.model} 
                        onChange={handleChange}
                    />
                </Form.Group>

                </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-secondary" onClick={handleCloseAddModal}>
                Fermer
                </Button>
                <Button variant="outline-primary" type="submit">
                Sauvegarder
                </Button>
            </Modal.Footer>
            </Form>
        </Modal>
        <Modal show={showEditModal} onHide={handleCloseEditModal} backdrop="static" size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Modifier un contact</Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleEditSubmit}>
                <Modal.Body>
                    <Autocomplete
                        freeSolo
                        options={options} // `options` est un tableau d'objets
                        getOptionLabel={(option) => `${option.name} ${option.surname || ''}, ${option.city || ''}`}
                        onInputChange={(event, newInputValue) => {
                            setQuery(newInputValue);
                        }}
                        renderInput={(params) => (
                            <TextField {...params} label="Rechercher un nom" variant="outlined" />
                        )}
                        onChange={(event, newValue) => {
                            if (newValue && typeof newValue === 'object') {
                                setCurrentContact({
                                    ...initialContactState, // Utilise l'état initial pour garantir des valeurs par défaut
                                    ...newValue, // Remplace par les valeurs sélectionnées, s'assure que toutes les clés existent déjà dans initialContactState
                                });
                            } else {
                                setCurrentContact({ ...initialContactState });
                            }
                        }}
                    />

                    <Row>
                        {/* Genre */}
                        <Col md={2}>
                            <Form.Group className="mb-3" controlId="formBasicGenre">
                                <Form.Label>Genre</Form.Label>
                                <Form.Select name="genre" onChange={handleChange} value={currentContact ? currentContact.genre : ''} >
                                    <option value="">Choisir...</option>
                                    <option value="Monsieur">Monsieur</option>
                                    <option value="Madame">Madame</option>
                                    <option value="Autre">Autre</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>

                        {/* Nom */}
                        <Col md={5}>
                            <Form.Group className="mb-3" controlId="formBasicName">
                                <Form.Label>Nom</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    name="name" 
                                    placeholder="Entrez le nom" 
                                    onChange={handleChange} 
                                    value={currentContact ? currentContact.name : ''} 
                                    required 
                                />
                            </Form.Group>
                        </Col>

                        {/* Prénom */}
                        <Col md={5}>
                            <Form.Group className="mb-3" controlId="formBasicFirstName">
                                <Form.Label>Prénom</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    name="firstname" 
                                    placeholder="Entrez le prénom" 
                                    onChange={handleChange} 
                                    value={currentContact ? currentContact.firstname : ''} 
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={6}>
                            {/* Email */}
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email" name="mail" placeholder="Entrez l'email" onChange={handleChange} value={currentContact ? currentContact.mail : ''}  />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            {/* Téléphone */}
                            <Form.Group className="mb-3" controlId="formBasicPhone">
                                <Form.Label>Téléphone</Form.Label>
                                <Form.Control type="text" name="phone" placeholder="Entrez le numéro de téléphone" onChange={handleChange} value={currentContact ? currentContact.phone : ''}  />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={6}>
                            {/* Rôle 1 */}
                            <Form.Group className="mb-3" controlId="formBasicRole1">
                                <Form.Label>Rôle 1</Form.Label>
                                <Form.Control type="text" name="role1" placeholder="Rôle principal" onChange={handleChange} value={currentContact ? currentContact.role1 : ''} />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            {/* Rôle 2 */}
                            <Form.Group className="mb-3" controlId="formBasicRole2">
                                <Form.Label>Rôle 2</Form.Label>
                                <Form.Control type="text" name="role2" placeholder="Rôle secondaire" onChange={handleChange} value={currentContact ? currentContact.role2 : ''} />
                            </Form.Group>
                        </Col>
                    </Row>

                    {/* Référence */}
                    <Form.Group className="mb-3" controlId="formBasicReference">
                        <Form.Label>Référence</Form.Label>
                        <Form.Control type="text" name="reference" placeholder="Référence" onChange={handleChange} value={currentContact ? currentContact.reference : ''} />
                    </Form.Group>

                    {/* Adresse */}
                    <Form.Group className="mb-3" controlId="formBasicAddress">
                        <Form.Label>Adresse</Form.Label>
                        <Row>
                            <Col md={2}>
                                <Form.Control placeholder="Boîte postale" name="letterbox" onChange={handleChange} value={currentContact ? currentContact.letterbox : ''} />
                            </Col>
                            <Col md={2}>
                                <Form.Control placeholder="Numéro" name="number" onChange={handleChange} value={currentContact ? currentContact.number : ''} />
                            </Col>
                            <Col md={8}>
                                <Form.Control placeholder="Rue" name="street" onChange={handleChange} value={currentContact ? currentContact.street : ''} />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}>
                                <Form.Control placeholder="Code postal" name="postcode" onChange={handleChange} value={currentContact ? currentContact.postcode : ''} />
                            </Col>
                            <Col md={8}>
                                <Form.Control placeholder="Ville" name="city" onChange={handleChange} value={currentContact ? currentContact.city : ''} />
                            </Col>
                        </Row>
                    </Form.Group>

                    {/* Compte Bancaire */}
                    <Form.Group className="mb-3" controlId="formBasicBankAccount">
                        <Form.Label>Compte Bancaire</Form.Label>
                        <Form.Control type="text" name="bank_account" placeholder="Compte bancaire" onChange={handleChange} value={currentContact ? currentContact.bank_account : ''} />
                    </Form.Group>

                    {/* Enregistrer comme modèle */}
                    <Form.Group className="mb-3" controlId="formBasicModel">
                        <Form.Check 
                            type="checkbox" 
                            label="Enregistrer comme modèle" 
                            name="model" 
                            checked={!!currentContact.model} 
                            onChange={handleChange}
                        />
                    </Form.Group>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-secondary" onClick={handleCloseEditModal}>
                        Fermer
                    </Button>
                    <Button variant="outline-primary" type="submit">
                        Sauvegarder
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>

        <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Confirmer la Suppression</Modal.Title>
            </Modal.Header>
            <Modal.Body>Êtes-vous sûr de vouloir supprimer ce contact ? Cette action est irréversible.</Modal.Body>
            <Modal.Footer>
                <Button variant="outline-secondary" onClick={() => setShowDeleteModal(false)}>
                    Annuler
                </Button>
                <Button variant="outline-danger" onClick={handleDeleteContact}>
                    Supprimer
                </Button>
            </Modal.Footer>
        </Modal>

      </Container>
    );
}

export default ListContact;
