import React, { useState } from 'react';
import { Container, Row, Col, Button, Nav } from 'react-bootstrap';



import OrganizationFile from '../../components/OrganizationFile/';
import Pdf from '../../components/Pdf/';
import TasksModel from '../../components/TasksModel/';
import TasksListModel from '../../components/TaskListModel/';
import ReportModelView from '../../components/ReportModel/reportModelView';



function ManagerExpertise() {

    return (
        <Container fluid>
            <Row style={{ paddingTop: '56px' }}>
                <OrganizationFile />
            </Row>
            <Row style={{ paddingTop: '56px' }}>
                <Pdf />
            </Row>
            <Row style={{ paddingTop: '56px' }}>
                <TasksModel />
            </Row>
            <Row style={{ paddingTop: '56px' }}>
                <TasksListModel />
            </Row>
            <Row style={{ paddingTop: '56px' }}>
                <ReportModelView />
            </Row>
        </Container>
    );
}

export default ManagerExpertise;
