import React, { useState, useEffect } from "react";
import { Button, Container, Modal, Form, ListGroup, Alert } from "react-bootstrap";
import axiosInstance from "../../axiosInstance";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ReportModelView = () => {
  const [reports, setReports] = useState([]);
  const [fields, setFields] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false); // Pour créer un modèle
  const [selectedReport, setSelectedReport] = useState(null);
  const [formData, setFormData] = useState({ name: "", actif: true });
  const [newReportData, setNewReportData] = useState({ name: "", actif: true }); // Pour le nouveau modèle
  const [fieldData, setFieldData] = useState({ title: "", type_field: "description" });
  const [error, setError] = useState("");

  const FIELD_TYPES = [
    { value: "description", label: "Description" },
    { value: "check_box", label: "Case à cocher" },
    { value: "at_date", label: "Date" },
  ];

  // Fetch existing reports
  const fetchReports = async () => {
    try {
      const response = await axiosInstance.get("/report_model/");
      setReports(response.data.results || response.data);
    } catch (err) {
      setError("Erreur lors du chargement des rapports");
    }
  };

  // Fetch fields for a specific report
  const fetchFields = async (reportId) => {
    try {
      const response = await axiosInstance.get(`/reportfieldtexts/?report_id=${reportId}`);
      setFields(response.data.results || response.data);
    } catch (err) {
      setError("Erreur lors du chargement des champs du rapport");
    }
  };

  useEffect(() => {
    fetchReports();
  }, []);

  // Handle opening the modal and setting the selected report
  const handleShowModal = (report) => {
    setSelectedReport(report);
    setFormData({ name: report.name, actif: report.actif });
    fetchFields(report.id);
    setShowModal(true);
  };

  // Handle input change for report form
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  // Handle input change for new report form
  const handleNewReportInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setNewReportData({
      ...newReportData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  // Handle input change for adding fields
  const handleFieldInputChange = (e) => {
    const { name, value } = e.target;
    setFieldData({
      ...fieldData,
      [name]: value,
    });
  };

  // Handle saving report changes
  const handleSaveChanges = async () => {
    if (!selectedReport) return;

    try {
      const response = await axiosInstance.put(`/report_model/${selectedReport.id}/`, formData);
      const updatedReports = reports.map((report) =>
        report.id === selectedReport.id ? response.data : report
      );
      setReports(updatedReports);
      setShowModal(false);
      toast.success("Rapport mis à jour avec succès !");
    } catch (err) {
      setError("Erreur lors de la mise à jour du rapport");
    }
  };

  // Handle adding a new field to the report
  const handleAddField = async () => {
    if (!selectedReport) return;

    const newField = { ...fieldData, report: selectedReport.id };

    try {
      const response = await axiosInstance.post("/reportfieldtexts/", newField);
      setFields([...fields, response.data]);
      setFieldData({ title: "", type_field: "description" });
      toast.success("Champ ajouté avec succès !");
    } catch (err) {
      setError("Erreur lors de l'ajout du champ");
    }
  };

  // Handle creating a new report
  const handleCreateReport = async () => {
    try {
      const response = await axiosInstance.post("/report_model/", newReportData);
      setReports([...reports, response.data]);
      setNewReportData({ name: "", actif: true });
      setShowCreateModal(false);
      toast.success("Nouveau modèle créé avec succès !");
    } catch (err) {
      setError("Erreur lors de la création du modèle");
    }
  };

  return (
    <Container>
      <ToastContainer position="top-right" autoClose={2000} />
      <h2>Gestion des Modèles de Rapport</h2>

      {/* Display errors */}
      {error && <Alert variant="danger">{error}</Alert>}

      {/* List of reports */}
      <div className="d-flex justify-content-between align-items-center">
        <h4>Modèles Disponibles</h4>
        <Button variant="primary" onClick={() => setShowCreateModal(true)}>
          Nouveau Modèle
        </Button>
      </div>
      <ListGroup className="mt-3">
        {reports.map((report) => (
          <ListGroup.Item key={report.id}>
            <div className="d-flex justify-content-between align-items-center">
              <span>{report.name}</span>
              <Button
                variant="info"
                size="sm"
                onClick={() => handleShowModal(report)}
              >
                Modifier
              </Button>
            </div>
          </ListGroup.Item>
        ))}
      </ListGroup>

      {/* Modal for editing a report */}
      <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Modifier le Modèle</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Form for report details */}
          <Form.Group>
            <Form.Label>Nom du Modèle</Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              placeholder="Entrez le nom du modèle"
            />
          </Form.Group>
          <Form.Group className="mt-3">
            <Form.Check
              type="checkbox"
              name="actif"
              label="Actif"
              checked={formData.actif}
              onChange={handleInputChange}
            />
          </Form.Group>

          <hr />

          {/* Fields management */}
          <h5>Champs du Modèle</h5>
          <ListGroup>
            {fields.map((field) => (
              <ListGroup.Item key={field.id}>
                <strong>{field.title}</strong> - {field.type_field}
              </ListGroup.Item>
            ))}
          </ListGroup>

          {/* Form to add a new field */}
          <h5 className="mt-4">Ajouter un Nouveau Champ</h5>
          <Form.Group>
            <Form.Label>Nom du Champ</Form.Label>
            <Form.Control
              type="text"
              name="title"
              value={fieldData.title}
              onChange={handleFieldInputChange}
              placeholder="Entrez le nom du champ"
            />
          </Form.Group>
          <Form.Group className="mt-3">
            <Form.Label>Type de Champ</Form.Label>
            <Form.Control
              as="select"
              name="type_field"
              value={fieldData.type_field}
              onChange={handleFieldInputChange}
            >
              {FIELD_TYPES.map((type) => (
                <option key={type.value} value={type.value}>
                  {type.label}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Button variant="success" className="mt-3" onClick={handleAddField}>
            Ajouter le Champ
          </Button>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Annuler
          </Button>
          <Button variant="primary" onClick={handleSaveChanges}>
            Sauvegarder
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal for creating a new report */}
      <Modal show={showCreateModal} onHide={() => setShowCreateModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Créer un Nouveau Modèle</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Nom du Modèle</Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={newReportData.name}
              onChange={handleNewReportInputChange}
              placeholder="Entrez le nom du modèle"
            />
          </Form.Group>
          <Form.Group className="mt-3">
            <Form.Check
              type="checkbox"
              name="actif"
              label="Actif"
              checked={newReportData.actif}
              onChange={handleNewReportInputChange}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowCreateModal(false)}>
            Annuler
          </Button>
          <Button variant="primary" onClick={handleCreateReport}>
            Sauvegarder
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default ReportModelView;
