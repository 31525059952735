import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axiosInstance from '../../axiosInstance'; 
import { Container, Row, Col, Button, Alert, Modal, Form, Table } from 'react-bootstrap';
import ReactQuill from 'react-quill'; // import the editor
import 'react-quill/dist/quill.snow.css'; // import the styles
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './index.css';

function TaskView() {
    const { expertiseId } = useParams();
    const [tasks, setTasks] = useState([]);
    const initialTaskState = {
        title: '',
        title_bis: '',
        comment: '',
        review_at: '',
        sender: '',
        receiver: '',
        copy_receiver: '',
        hierarchy: '',
        public: false,  // Assuming this should be boolean based on the model default
        status: 'notstart',  // Default status
    };
    const [currentTask, setCurrentTask] = useState({ ...initialTaskState });
    const [showAddModal, setShowAddModal] = useState(false);
    const [error, setError] = useState('');
    const [showEditModal, setShowEditModal] = useState(false);
    const [editingTaskId, setEditingTaskId] = useState(null);
    const [pdfFiles, setPdfFiles] = useState([]);
    const [taskListModels, setTaskListModels] = useState([]);
    const [selectedTaskListModel, setSelectedTaskListModel] = useState('');
    const [showViewModal, setShowViewModal] = useState(false);
    const [viewTask, setViewTask] = useState(null); // Tâche à afficher
    const [displayMode, setDisplayMode] = useState('restricted'); // 'restricted' ou 'extended'
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);



    const ReactQuillModules = {
        toolbar: [
            [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
            [{ 'size': [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{'list': 'ordered'}, {'list': 'bullet'}, 
             {'indent': '-1'}, {'indent': '+1'}],
            ['clean']
        ],
    };
    
    const ReactQuillFormats = [
        'header', 'font', 'size',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent'
    ];
    

    const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        return new Intl.DateTimeFormat('fr-FR', { day: '2-digit', month: '2-digit', year: 'numeric' }).format(date);
    };

    useEffect(() => {
        fetchTasks(currentPage);
        fetchPDFFiles(); 
        fetchTaskListModels(); 
    }, [currentPage, expertiseId]);

    const fetchTaskListModels = async () => {
        try {
            const response = await axiosInstance.get(`/tasks_list_model/`);
            setTaskListModels(response.data.results || response.data);
        } catch (error) {
            console.error('Erreur lors de la récupération des modèles de liste de tâches:', error);
            setError('Erreur lors de la récupération des modèles de liste de tâches');
        }
    };
    
    // Fonction pour charger les tâches
    const fetchTasks = async (page = 1) => {
        try {
            const response = await axiosInstance.get(`/tasks?expertise_id=${expertiseId}&page=${page}`);
            const data = response.data;
            setTasks(data.results || data);
            setTotalPages(data.total_pages || 1); // Assurez-vous que le backend inclut 'total_pages'
            setError('');
        } catch (err) {
            setError('Erreur lors de la récupération des tâches');
        }
    };
    

    const fetchPDFFiles = async () => {
        try {
            const response = await axiosInstance.get(`/templates/`);
            setPdfFiles(response.data.results || response.data); // Assurez-vous que le format de la réponse correspond
        } catch (error) {
            console.error('Erreur lors de la récupération des PDFs:', error);
        }
    };

    const handleShowAddModal = () => setShowAddModal(true);
    const handleCloseAddModal = () => {
        setShowAddModal(false);
        setCurrentTask({ ...initialTaskState });
    };

    const handleShowEditModal = (task) => {
        const editedTask = {
            id: task.id, 
            title: task.title || '',
            title_bis: task.title_bis || '',
            comment: task.comment || '',
            review_at: task.review_at ? task.review_at.split('T')[0] : '',
            sender: task.sender || '',
            receiver: task.receiver || '',
            copy_receiver: task.copy_receiver || '',
            hierarchy: task.hierarchy || '',
            public: task.public !== undefined ? task.public : false,  // Ensure boolean fields are not set to undefined
            status: task.status || 'notstart',
        };
    
        setCurrentTask(editedTask);
        setEditingTaskId(task.id);
        setShowEditModal(true);
    };
    
    

    const handleCloseEditModal = () => {
        setShowEditModal(false);
        setCurrentTask({ ...initialTaskState });
        setEditingTaskId(null);
    };
    
    

    const handleChange = (e) => {
        let name, value;
    
        // Check if event has a target property, typical for standard inputs
        if (e.target) {
            name = e.target.name;
            value = e.target.value;
        } else {
            // For ReactQuill, assume 'comment' as name since it's the only Quill editor
            name = 'comment';
            value = e;
        }
    
        // Update the state with the new value
        setCurrentTask(prevTask => ({
            ...prevTask,
            [name]: value
        }));
    };
    
    const handleShowViewModal = (task) => {
        setViewTask(task);
        setShowViewModal(true);
    };
    
    const handleCloseViewModal = () => {
        setShowViewModal(false);
        setViewTask(null); // Réinitialiser la tâche
    };
    
    const handleAddSubmit = async (e) => {
        e.preventDefault();
        const formattedData = {
            ...currentTask,
            review_at: currentTask.review_at ? new Date(currentTask.review_at).toISOString().split('T')[0] : null,
            expertise: expertiseId,
        };
        try {
            const response = await axiosInstance.post(`/tasks/`, formattedData );
            setTasks([...tasks, response.data]);  // Update the list with the new task
            handleCloseAddModal();
        } catch (error) {
            setError('Erreur lors de l\'ajout de la tâche');
        }
    };

    const handleEditSubmit = async (e) => {
        e.preventDefault();
        try {
            const formattedData = {
                ...currentTask,
                review_at: currentTask.review_at ? new Date(currentTask.review_at).toISOString().split('T')[0] : null,
            };
            const response = await axiosInstance.put(`/tasks/${editingTaskId}/`, formattedData);
            const updatedTasks = tasks.map(t => t.id === editingTaskId ? response.data : t);
            setTasks(updatedTasks);
            handleCloseEditModal();
        } catch (error) {
            setError('Erreur lors de la modification de la tâche');
        }
    };

    // Fonction pour sauvegarder sans fermer le modal
    const handleSaveWithoutClosing = async (e) => {
        e.preventDefault();
        try {
            const formattedData = {
                ...currentTask,
                review_at: currentTask.review_at ? new Date(currentTask.review_at).toISOString().split('T')[0] : null,
            };
            const response = await axiosInstance.put(`/tasks/${editingTaskId}/`, formattedData);
            const updatedTasks = tasks.map(t => t.id === editingTaskId ? response.data : t);
            setTasks(updatedTasks);
            // Modal reste ouvert ici
        } catch (error) {
            setError('Erreur lors de la modification de la tâche');
        }
    };

    // Fonction pour sauvegarder et fermer le modal
    const handleSaveAndClose = async (e) => {
        e.preventDefault();
        try {
            const formattedData = {
                ...currentTask,
                review_at: currentTask.review_at ? new Date(currentTask.review_at).toISOString().split('T')[0] : null,
            };
            const response = await axiosInstance.put(`/tasks/${editingTaskId}/`, formattedData);
            const updatedTasks = tasks.map(t => t.id === editingTaskId ? response.data : t);
            setTasks(updatedTasks);
            handleCloseEditModal(); // Ferme le modal après la sauvegarde
        } catch (error) {
            setError('Erreur lors de la modification de la tâche');
        }
    };


    
    const handlePDFSelect = async (event) => {
        const selectedPDFId = event.target.value;
        if (selectedPDFId) {
            try {
                console.log('expertiseId: ', expertiseId)
                const url = expertiseId ? `/templates/${selectedPDFId}/?expertise_id=${expertiseId}` : `/templates/${selectedPDFId}/`;
                const response = await axiosInstance.get(url);
                const selectedPDF = response.data;
                setCurrentTask(prevTask => ({
                    ...prevTask,
                    comment: selectedPDF.content  // Assurez-vous que 'content' est le bon champ
                }));
            } catch (error) {
                console.error('Erreur lors de la récupération du contenu du PDF:', error);
            }
        }
    };

    // Fonction pour sauvegarder puis envoyer un email
    const handleSaveAndSendEmail = async (e) => {
        e.preventDefault();
        try {
            // Sauvegarder la tâche
            const formattedData = {
                ...currentTask,
                review_at: currentTask.review_at ? new Date(currentTask.review_at).toISOString().split('T')[0] : null,
            };
            const response = await axiosInstance.put(`/tasks/${editingTaskId}/`, formattedData);
            
            // Mettre à jour la liste des tâches
            const updatedTasks = tasks.map(t => t.id === editingTaskId ? response.data : t);
            setTasks(updatedTasks);
            
            // Envoyer l'email après la sauvegarde réussie
            await axiosInstance.post(`/tasks/${editingTaskId}/send-task-email/`);
            
            alert("La tâche a été sauvegardée et l'email a été envoyé.");
            
            // Fermer le modal si nécessaire
            handleCloseEditModal();
        } catch (error) {
            setError('Erreur lors de la modification ou de l\'envoi de l\'email.');
        }
    };


    const handleAddTemplateToList = async () => {
        if (!selectedTaskListModel) {
            alert('Veuillez sélectionner un modèle de liste de tâches.');
            return;
        }
        try {
            const response = await axiosInstance.post(`/tasks/add_from_model/`, {
                task_list_model_id: selectedTaskListModel,
                expertise_id: expertiseId
            });
            // Supposons que la réponse contienne la nouvelle liste des tâches
            setTasks(response.data.tasks || []);
            setSelectedTaskListModel('');  // Reset le champ après l'ajout
            setError('');
        } catch (error) {
            console.error('Erreur lors de l\'ajout des tâches prédéfinies:', error);
            setError('Erreur lors de l\'ajout des tâches prédéfinies');
        }
    };
    
    const toggleDisplayMode = () => {
        setDisplayMode((prevMode) => (prevMode === 'restricted' ? 'extended' : 'restricted'));
    };

    const filteredTasks =
    displayMode === 'restricted'
        ? tasks.filter(
            (task) => 
                !task.hierarchy?.toLowerCase().startsWith('x') && 
                (task.status === 'start' || task.status === 'complete')
          )
        : tasks;

    const markAsComplete = async (taskId) => {
        try {
            // Mettre à jour la tâche côté serveur
            await axiosInstance.patch(`/tasks/${taskId}/`, { status: 'complete' });
    
            // Recharger la liste des tâches depuis le serveur
            await fetchTasks(); // Appel de la fonction pour récupérer les tâches mises à jour
    
            toast.success('Tâche marquée comme complétée avec succès !');
        } catch (error) {
            console.error('Erreur lors de la mise à jour de la tâche :', error);
            toast.error('Erreur lors de la mise à jour de la tâche.');
        }
    };
        
    

    const getStatusSvg = (status) => {
        switch (status) {
            case "start":
                return <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg"><circle cx="10" cy="10" r="8" fill="green" /></svg>;
            case "delete":
                return <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg"><line x1="4" y1="4" x2="16" y2="16" stroke="red" strokeWidth="3" /><line x1="16" y1="4" x2="4" y2="16" stroke="red" strokeWidth="3" /></svg>;
            case "notstart":
                return <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg"><circle cx="10" cy="10" r="8" fill="grey" /></svg>;
            case "complete":
                return <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg"><polyline points="4,11 8,15 16,6" stroke="green" strokeWidth="3" fill="none" strokeLinecap="round" strokeLinejoin="round"/></svg>;
            default:
                return null;
        }
    };
    return (
        <Container fluid>
            <h2 className="expertise-title">Suivi du dossier</h2>
            <Form.Group controlId="taskListModelSelect">
                <Row className="align-items-center">
                    <Col md="auto">
                    <Form.Label>Choisir un modèle de suivis</Form.Label>
                    </Col>
                    <Col md={6}>
                    <Form.Control
                        as="select"
                        value={selectedTaskListModel}
                        onChange={e => setSelectedTaskListModel(e.target.value)}
                    >
                        <option value="">Sélectionner un modèle...</option>
                        {taskListModels.map(model => (
                        <option key={model.id} value={model.id}>{model.title}</option>
                        ))}
                    </Form.Control>
                    </Col>
                    <Col md="2">
                    <Button variant="primary" onClick={handleAddTemplateToList}>
                        Ajouter le model
                    </Button>
                    </Col>
                </Row>
            </Form.Group>


            <hr className="text-info" style={{ borderColor: 'currentColor' }} />
            <Button variant="primary" onClick={handleShowAddModal}>Ajouter un suivi</Button>

            <Button onClick={toggleDisplayMode} variant="secondary" >
                {displayMode === 'restricted' ? 'Afficher tout (Mode Étendu)' : 'Afficher moins (Mode Restreint)'}
            </Button>

            {error && <Alert variant="danger">{error}</Alert>}
            <div class="table-container">
                <Table striped bordered hover>
                <ToastContainer position="top-right" autoClose={2000} />
                    <thead>
                        <tr>
                            <th>Statut</th>
                            <th>Classement</th>
                            <th>Titre</th>
                            <th>Titulaire</th>
                            <th>Echéance</th>
                            <th class="fixed-column">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredTasks.map(task => (
                            <tr key={task.id}>
                                <td>{getStatusSvg(task.status)}</td>
                                <td>{task.hierarchy}</td>
                                <td>{task.title}</td>
                                <td>{task.receiver}</td>
                                <td>{formatDate(task.review_at)}</td>
                                <td class="fixed-column">
                                    {/* Vérifie si hierarchy ne commence pas par "X" ou "x" */}
                                    {(task.hierarchy === null || !task.hierarchy.toLowerCase().startsWith('x')) && (
                                        <>
                                            <Button variant="outline-primary" size="sm" onClick={() => handleShowEditModal(task)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                                    <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5L13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5H5h.5v.5v.5v.5h.293l6.5-6.5zm-9.761 5.175l-.106.106-1.528 3.821 3.821-1.528.106-.106L3.707 10 2.032 10.675zm-.032 3.282l-.394.986.986-.394 2.598-2.598-.978-.978-2.212 2.984z"/>
                                                </svg>
                                            </Button>
                                            <Button 
                                                variant="outline-success" 
                                                size="sm" 
                                                onClick={() => markAsComplete(task.id)}
                                            >
                                                <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg"><polyline points="4,11 8,15 16,6" stroke="green" strokeWidth="3" fill="none" strokeLinecap="round" strokeLinejoin="round"/></svg>
                                            </Button>
                                        </>
                                    )}
                                    
                                    {/* Vérifie si hierarchy commence par "X" ou "x" */}
                                    {task.hierarchy && task.hierarchy.toLowerCase().startsWith('x') && (
                                        <Button variant="secondary" size="sm" onClick={() => handleShowViewModal(task)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                                <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.12 12.5 8 12.5s-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.173 8z"/>
                                                <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                                            </svg>
                                        </Button>
                                        
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>

            <Modal backdrop="static" size="xl" show={showAddModal} onHide={handleCloseAddModal}>
            <Form onSubmit={handleAddSubmit}>
            <Modal.Header closeButton>
                <Modal.Title>Ajouter un nouveau suivi</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                    <Row>
                        <Col>
                            <Form.Group controlId="formTaskTitle">
                                <Form.Label>Titre</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="title"
                                    value={currentTask.title}
                                    onChange={handleChange}
                                    placeholder="Entrez le titre de la tâche"
                                    required
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <hr className="text-info" style={{ borderColor: 'currentColor' }} />
                    <Row>
                        <Col md="6">
                        <Form.Group controlId="formTaskStatus">
                            <Form.Label>Status</Form.Label>
                            <Form.Control
                                as="select"
                                name="status"
                                value={currentTask.status}
                                onChange={handleChange}
                            >
                                <option value="start">Commencée</option>
                                <option value="delete">Annulée</option>
                                <option value="notstart">Pas commencée</option>
                                <option value="complete">Achevée</option>
                            </Form.Control>
                        </Form.Group>
                        </Col>
                        <Col md="6">
                        <Form.Group controlId="formTaskPublic">
                            <Form.Label>Public</Form.Label>
                            <Form.Check
                                type="checkbox"
                                name="public"
                                checked={currentTask.public}
                                onChange={e => handleChange({ target: { name: e.target.name, value: e.target.checked } })}
                                label="Est-ce que la tâche est publique ?"
                            />
                        </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                        <Form.Group controlId="formTaskReviewAt">
                            <Form.Label>Date de révision</Form.Label>
                            <Form.Control
                                type="date"
                                name="review_at"
                                value={currentTask.review_at}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        </Col>
                        <Col md="6">
                        <Form.Group controlId="formTaskHierarchy">
                            <Form.Label>Hiérarchie</Form.Label>
                            <Form.Control
                                type="text"
                                name="hierarchy"
                                value={currentTask.hierarchy}
                                onChange={handleChange}
                                placeholder="Niveau de hiérarchie de la tâche"
                            />
                        </Form.Group>
                        </Col>
                    </Row>
                    <hr className="text-info" style={{ borderColor: 'currentColor' }} />
                    <Form.Group controlId="formTaskSender">
                        <Form.Label>Expéditeur</Form.Label>
                        <Form.Control
                            type="text"
                            name="sender"
                            value={currentTask.sender}
                            onChange={handleChange}
                            placeholder="Email de l'expéditeur"
                        />
                    </Form.Group>
                    <Form.Group controlId="formTaskReceiver">
                        <Form.Label>Destinataire</Form.Label>
                        <Form.Control
                            type="text"
                            name="receiver"
                            value={currentTask.receiver}
                            onChange={handleChange}
                            placeholder="Email du destinataire"
                        />
                    </Form.Group>
                    <Form.Group controlId="formTaskCopyReceiver">
                        <Form.Label>Copie à</Form.Label>
                        <Form.Control
                            type="text"
                            name="copy_receiver"
                            value={currentTask.copy_receiver}
                            onChange={handleChange}
                            placeholder="Email des autres destinataires"
                        />
                    </Form.Group>
                    <Form.Group controlId="formTaskTitleBis">
                        <Form.Label>Sujet</Form.Label>
                        <Form.Control
                            type="text"
                            name="title_bis"
                            value={currentTask.title_bis}
                            onChange={handleChange}
                            placeholder="Titre du courriel"
                        />
                    </Form.Group>
                    <Form.Group controlId="formPDFSelect">
                        <Form.Label>Sélectionner un template</Form.Label>
                        <Form.Control as="select" onChange={handlePDFSelect}>
                            <option value="">Choisir...</option>
                            {pdfFiles.map(pdf => (
                                <option key={pdf.id} value={pdf.id}>{pdf.title}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="formTaskComment">
                        <Form.Label>Corps du message</Form.Label>
                        <ReactQuill
                            theme="snow"
                            value={currentTask.comment}
                            onChange={handleChange} // Directly pass the new content
                            placeholder="Entrez votre texte ici..."
                            modules={ReactQuillModules}
                            formats={ReactQuillFormats}
                            style={{ height: '420px' }} // Adjust the height as needed
                        />
                    </Form.Group>
            </Modal.Body>
            <br />
            <br />
            <br />
            <br />
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseAddModal}>
                    Fermer
                </Button>
                <Button variant="primary" type="submit">
                    Sauvegarder
                </Button>
            </Modal.Footer>
            </Form>
        </Modal>
        <Modal backdrop="static" size="xl" show={showEditModal} onHide={handleCloseEditModal}>
        <Form onSubmit={handleEditSubmit}>
            <Modal.Header closeButton>
                <Modal.Title>Modifier la tâche</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                        <Col>
                            <Form.Group controlId="formTaskTitle">
                                <Form.Label>Titre</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="title"
                                    value={currentTask.title}
                                    onChange={handleChange}
                                    placeholder="Entrez le titre de la tâche"
                                    required
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <hr className="text-info" style={{ borderColor: 'currentColor' }} />
                    <Row>
                        <Col md="6">
                        <Form.Group controlId="formTaskStatus">
                            <Form.Label>Status</Form.Label>
                            <Form.Control
                                as="select"
                                name="status"
                                value={currentTask.status}
                                onChange={handleChange}
                            >
                                <option value="start">Commencée</option>
                                <option value="delete">Annulée</option>
                                <option value="notstart">Pas commencée</option>
                                <option value="complete">Achevée</option>
                            </Form.Control>
                        </Form.Group>
                        </Col>
                        <Col md="6">
                        <Form.Group controlId="formTaskPublic">
                            <Form.Label>Public</Form.Label>
                            <Form.Check
                                type="checkbox"
                                name="public"
                                checked={currentTask.public}
                                onChange={e => handleChange({ target: { name: e.target.name, value: e.target.checked } })}
                                label="Est-ce que la tâche est publique ?"
                            />
                        </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                        <Form.Group controlId="formTaskReviewAt">
                            <Form.Label>Date de révision</Form.Label>
                            <Form.Control
                                type="date"
                                name="review_at"
                                value={currentTask.review_at}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        </Col>
                        <Col md="6">
                        <Form.Group controlId="formTaskHierarchy">
                            <Form.Label>Hiérarchie</Form.Label>
                            <Form.Control
                                type="text"
                                name="hierarchy"
                                value={currentTask.hierarchy}
                                onChange={handleChange}
                                placeholder="Niveau de hiérarchie de la tâche"
                            />
                        </Form.Group>
                        </Col>
                    </Row>
                    <hr className="text-info" style={{ borderColor: 'currentColor' }} />
                    <Form.Group controlId="formTaskSender">
                        <Form.Label>Expéditeur</Form.Label>
                        <Form.Control
                            type="text"
                            name="sender"
                            value={currentTask.sender}
                            onChange={handleChange}
                            placeholder="Email de l'expéditeur"
                        />
                    </Form.Group>
                    <Form.Group controlId="formTaskReceiver">
                        <Form.Label>Destinataire</Form.Label>
                        <Form.Control
                            type="text"
                            name="receiver"
                            value={currentTask.receiver}
                            onChange={handleChange}
                            placeholder="Email du destinataire"
                        />
                    </Form.Group>
                    <Form.Group controlId="formTaskCopyReceiver">
                        <Form.Label>Copie à</Form.Label>
                        <Form.Control
                            type="text"
                            name="copy_receiver"
                            value={currentTask.copy_receiver}
                            onChange={handleChange}
                            placeholder="Email des autres destinataires"
                        />
                    </Form.Group>
                    <Form.Group controlId="formTaskTitleBis">
                        <Form.Label>Sujet</Form.Label>
                        <Form.Control
                            type="text"
                            name="title_bis"
                            value={currentTask.title_bis}
                            onChange={handleChange}
                            placeholder="Titre du courriel"
                        />
                    </Form.Group>
                    <Form.Group controlId="formPDFSelect">
                        <Form.Label>Sélectionner un template</Form.Label>
                        <Form.Control as="select" onChange={handlePDFSelect}>
                            <option value="">Choisir...</option>
                            {pdfFiles.map(pdf => (
                                <option key={pdf.id} value={pdf.id}>{pdf.title}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="formTaskComment">
                        <Form.Label>Corps du message</Form.Label>
                        <ReactQuill
                            theme="snow"
                            value={currentTask.comment}
                            onChange={handleChange} // Directly pass the new content
                            placeholder="Entrez votre texte ici..."
                            modules={ReactQuillModules}
                            formats={ReactQuillFormats}
                            style={{ height: '420px' }} // Adjust the height as needed
                        />
                    </Form.Group>

            </Modal.Body>
            <br />
            <br />
            <br />
            <br />
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseEditModal}>Fermer</Button>
                {/* Bouton "Sauvegarder" qui ne ferme pas le modal */}
                <Button variant="primary" onClick={handleSaveWithoutClosing}>
                    Sauvegarder
                </Button>
                <Button variant="primary" onClick={handleSaveAndClose}>
                    Sauvegarder et Quitter
                </Button>
                <Button variant="primary" onClick={handleSaveAndSendEmail}>
                    Sauvegarder et Envoyer l'email
                </Button>
            </Modal.Footer>
            </Form>
        </Modal>
        <Modal show={showViewModal} onHide={handleCloseViewModal}>
            <Modal.Header closeButton>
                <Modal.Title>Voir la tâche</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {viewTask && (
                    <>
                        <p><strong>Titre :</strong> {viewTask.title}</p>
                        <p><strong>Titre bis :</strong> {viewTask.title_bis}</p>
                        <p><strong>Commentaire :</strong></p>
                        <div dangerouslySetInnerHTML={{ __html: viewTask.comment }}></div> {/* Pour afficher le texte riche */}
                        <p><strong>Date de révision :</strong> {formatDate(viewTask.review_at)}</p>
                        <p><strong>Expéditeur :</strong> {viewTask.sender}</p>
                        <p><strong>Destinataire :</strong> {viewTask.receiver}</p>
                        <p><strong>Copie à :</strong> {viewTask.copy_receiver}</p>
                        <p><strong>Hiérarchie :</strong> {viewTask.hierarchy}</p>
                        <p><strong>Statut :</strong> {viewTask.status}</p>
                        <p><strong>Public :</strong> {viewTask.public ? 'Oui' : 'Non'}</p>
                    </>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseViewModal}>
                    Fermer
                </Button>
            </Modal.Footer>
        </Modal>

        </Container>
    );
}

export default TaskView;
