import React from 'react';
import { Button } from 'react-bootstrap';

function InfoPopover() {
    const handleOpenWindow = () => {
        const popoverContent = `
            <html>
                <head>
                    <title>Données pouvant être utilisées</title>
                    <style>
                        body { font-family: Arial, sans-serif; padding: 20px; line-height: 1.6; }
                        h2, h3 { color: #007bff; }
                        ul { list-style-type: disc; margin-left: 20px; }
                    </style>
                </head>
                <body>
                    <h2>Données pouvant être utilisées</h2>
                    <div>
                        <h3>Données générales</h3>
                        <ul>
                            <li>{{today_date}}</li>
                            <li>{{reference_interne}}</li>
                            <li>{{expertise_nom}}</li>
                            <li>{{claim_reference_cie}}</li>
                            <li>{{claim_garantees}}</li>
                            <li>{{claim_date}}</li>
                            <li>{{claim_adress}}</li>
                            <li>{{insurance_contract_reference}}</li>
                            <li>{{insurance_contract_cie}}</li>
                        </ul>
                        <h3>Données sur les images</h3>
                        <ul>
                        <li>{{photos}}</li>
                        <li>{{photo.title}}</li>
                        <li>{{photo.description}}</li>
                        <li>{{photo.category}}</li>
                        <li>{{photo.groupe}}</li>
                        <li>{{photo.file}}</li>
                        </ul>
                        <h3>Données sur les personnes</h3>
                        <ul>
                            <li>{{personnes = []}}</li>
                            <li>{{personne.genre}}</li>
                            <li>{{personne.name}}</li>
                            <li>{{personne.firstname}}</li>
                            <li>{{personne.mail}}</li>
                            <li>{{personne.phone}}</li>
                            <li>{{personne.role}}</li>
                            <li>{{personne.reference}}</li>
                            <li>{{personne.letterbox}}</li>
                            <li>{{personne.number}}</li>
                            <li>{{personne.street}}</li>
                            <li>{{personne.city}}</li>
                            <li>{{personne.postcode}}</li>
                            <li>{{personne.country}}</li>
                        </ul>
                        <h3>Données sur le rapport</h3>
                        <ul>
                            <li>{{report_fields}}</li>
                            <li>{{report_fields.title}}</li>
                            <li>{{report_fields.description}}</li>
                            <li>{{report_fields.at_date}}</li>
                            <li>{{report_fields.check_box}}</li>

                        </ul>
                    </div>
                </body>
            </html>
        `;
        const newWindow = window.open('', '_blank', 'width=800,height=600');
        newWindow.document.write(popoverContent);
        newWindow.document.close();
    };

    return (
        <Button variant="info" size="sm" onClick={handleOpenWindow}>
            Voir les informations
        </Button>
    );
}

export default InfoPopover;
